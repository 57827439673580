import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { IForm, IFile, IApplication, InsurantType, ChangeReason, HealthInsurance, PastInsuranceRelation, Gender, FamilyStatus } from 'froitzheim-shared';
import { Service } from './base.service';
import { Observable, switchMap } from 'rxjs';
import { FormField, LoadEventArgs, PdfViewerComponent } from '@syncfusion/ej2-angular-pdfviewer';
import { ConfigService } from './config.service';
import { EnumMap, EnumToRecord } from 'src/helpers/enum.helpers';
import { DateTime } from 'luxon';
import insurances from '../assets/insurances.json';
@Injectable()
export class FormService extends Service<IForm> {
    constructor(override http: HttpClient) {
        super();
    }

    override readonly path = '/form';

    uploadFile(file: File) {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<{path: string}>(`${this.getUrl()}/upload/${file.name}`, formData, this.getAuthHeaders());
    }

    deleteFile(id: number, file: IFile) {
        return this.http.delete(`${this.getUrl()}/${id}/download/${file.fileName}`, this.getAuthHeaders());
    }

    async loadFile(data: {form: IForm, viewer: PdfViewerComponent, promise?: (value: string | PromiseLike<string>) => void}) {
        const promise = new Promise<string>((resolve, reject) => {
            data.promise = resolve;
        });
        data.viewer.documentPath = await this.fetchFile(data.form.filePath);
        return promise;
    }

    public static onLoad(x: LoadEventArgs, data: {form: IForm, fields: Field[], viewer: PdfViewerComponent, promise?: (value: string | PromiseLike<string>) => void, application?: IApplication}) {
        const {form, fields, viewer, promise, application} = data;
        form?.fields.forEach(x => {
            console.log('adding', x);
            viewer.formDesignerModule.addFormField(x.type, <any>{ 
              bounds: {
                X: x.bounds.x ?? 0,
                Y: x.bounds.y ?? 0,
                Width: x.bounds.width ?? 0,
                Height: x.bounds.height ?? 0
              }, 
              name: x.name,
              fontFamily: 'Courier',
              fontSize: 10,
              fontStyle: 'None',
              color: 'black',
              borderColor: 'black',
              backgroundColor: 'white',
              alignment: 'Left',
            });
        });

        const formFields = viewer.formFields ?? [];
        if(form?.bindings){
            Object.entries(form.bindings).forEach(([key, value]) => {
              const field = fields.find(f => f.label === key);
              if(!field)
                return;
              const formField = formFields.find(f => f.name === value);
              if (!formField) return;
              field.formField = formField.name ?? null;
            });
        }

        if (application) {
          fields.forEach(field => {
            if(!field.formField) return;
            const formFields = viewer.retrieveFormFields();
            const formField = formFields.find(f => f.name == field.formField);
            if(!formField) return;
            formField.value = field.path(application);
            console.log(field.label, formField.value);
            viewer.updateFormFieldsValue(formField);
          });
        }
        promise?.(x.pageData);
    }

    async prinfFile(form: IForm, fields: Field[], viewer: PdfViewerComponent, application: IApplication) {
        await this.loadFile({form, viewer});
        console.log('print', application);
        viewer.print.print();
    }

    private async fetchFile(url: string) {
        console.log('fetch', url);
        return new Promise<string>((resolve, reject) => {
            const subscription = this.http.get(ConfigService.settings.app.url + '/'+ url, { responseType: 'blob' }).pipe(
              switchMap(blob => this.convertBlobToBase64(blob))
            ).subscribe((base64ImageUrl: string) => {
                resolve(base64ImageUrl);
                subscription?.unsubscribe();
            });
        });
    }
    

    private  convertBlobToBase64(blob: Blob) {
        return new Observable((observer: { next: (arg0: any) => void; complete: () => void; }) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = (event: any) => {
            observer.next(event.target.result);
            observer.complete();
          };
    
          reader.onerror = (event: any) => {
            observer.next(event.target.error.code);
            observer.complete();
          };
        });
      }
}


export type Field = {
    label: string;
    path: (a: IApplication) => string | undefined,
    formField: string | null
}

const getEnumName = (map: EnumMap, value?: number) => {
    if(!value)
      return '';
    return map[value];
};
const date2string = (date?: Date) => {
    return date ? DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT, { locale: 'de' }) : '';
};
const insurantType: EnumMap = EnumToRecord(InsurantType);
const reasons: EnumMap = EnumToRecord(ChangeReason);
const healthInsurances: HealthInsurance[] = insurances;
const pastInsuranceRelations: EnumMap = EnumToRecord(PastInsuranceRelation);
const genders: EnumMap = EnumToRecord(Gender);
const familyStates: EnumMap = EnumToRecord(FamilyStatus);

export const getFields = (form: IForm, formFields: FormField[]) => {
    const fields = (fieldOptions).filter(x => form.bindings[x.label]);
    if(form?.bindings){
        Object.entries(form.bindings).forEach(([key, value]) => {
          const field = fields.find(f => f.label === key);
          if(!field)
            return;
          const formField = formFields.find(f => f.name === value);
          if (!formField) return;
          field.formField = formField.name ?? null;
        });
    }
    return fields;
};
export const fieldOptions: Field[] = [
    {
      label: 'Beschäftigung',
      path: x => getEnumName(insurantType, x.insurance?.type ),
      formField: null
    },
    {
      label: 'Wunschbeginn',
      path: x => date2string(x.start_date),
      formField: null
    },
    {
      label: 'Anlass des Krankenkassenwechsels',
      path: x => x.reason ? reasons[x.reason]: '',
      formField: null
    },
    {
      label: 'Krankenkasse',
      path: x => healthInsurances.find(h => h.id === x.insurance?.healthInsurance)?.name,
      formField: null
    },
    {
      label: 'Tippgeber',
      path: x => x.referer ? `${x.referer.firstName} ${x.referer.name}` : '',
      formField: null
    },
    //2nd field
    {
      label: 'Vorname',
      path: x => x.person?.firstName,
      formField: null
    },
    {
      label: 'Nachname',
      path: x => x.person?.name,
      formField: null
    },
    {
      label: 'Geschlecht',
      path: x => getEnumName(genders, x.person?.gender),
      formField: null
    },
    {
      label: 'Familienstand',
      path: x => getEnumName(familyStates, x.person?.familyStatus),
      formField: null
    },
    {
      label: 'Geburtsdatum',
      path: x => date2string(x.person?.birthday),
      formField: null
    },
    {
      label: 'Telefonnummer',
      path: x => x.person?.phone,
      formField: null
    },
    {
      label: 'E-Mail',
      path: x => x.person?.email,
      formField: null
    },
    {
      label: 'Straße',
      path: x => x.person?.street,
      formField: null
    },
    {
      label: 'Hausnummer',
      path: x => x.person?.streetNumber,
      formField: null,
    },
    {
      label: 'Stadt',
      path: x => x.person?.city,
      formField: null
    },
    {
      label: 'PLZ',
      path: x => x.person?.postcode,
      formField: null
    },
    //3rd field
    {
      label: 'Arbeitgebername',
      path: x => x.employment?.employer?.name,
      formField: null
    },
    //Bisherige Krankenversicherung 4th field
    {
      label: 'Krankenkasse',
      path: x => healthInsurances.find(h => h.id === x.previousInsurance?.healthInsurance)?.name,
      formField: null
    },
    {
      label: 'Versicherungsnummer',
      path: x => x.insurance?.insurance_number,
      formField: null
    },
    {
      label: 'Von',
      path: x => date2string(x.insurance?.start_date),
      formField: null
    },
    {
      label: 'Bis',
      path: x => date2string(x.insurance?.end_date),
      formField: null
    },
    {
      label: 'Ich war zuletzt',
      path: x => x.insurance?.pastInsuranceRelation ? pastInsuranceRelations[x.insurance.pastInsuranceRelation] : '',
      formField: null
    },
    {
      label: 'Seit',
      path: x => date2string(x.insurance?.data?.notInsuredSince),
      formField: null
    },
    {
      label: 'Grund',
      path: x => x.insurance?.data?.notInsuredReason,
      formField: null
    },
    {
      label: 'familienversichert über',
      path: x => x.insurance?.data?.familyInsuredName,
      formField: null
    },
    {
      label: 'Versicherungsnummer des Familienversicherers',
      path: x => x.insurance?.data?.familyInsuredNumber,
      formField: null
    },
    //Bankverbindung 5th field
    {
      label: 'IBAN',
      path: x => x.person?.iban,
      formField: null
    },
    {
      label: 'BIC',
      path: x => x.person?.bic,
      formField: null
    },

  ];