import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { PersonService } from 'src/services/person.service';
import { AuthService } from 'src/services/auth.service';
import { AuthInterceptor } from 'src/interceptor/auth.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastService } from 'src/services/toast.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApplicationService } from 'src/services/application.service';
import { EmployerService } from 'src/services/employer.service';
import { EmploymentService } from 'src/services/employment.service';
import { InsuranceService } from 'src/services/insurance.service';
import { ConfigService } from 'src/services/config.service';
import { RefererService } from 'src/services/referer.service';
import { AddressService } from 'src/services/address.service';
import { FormsModule, NG_VALIDATORS, ReactiveFormsModule } from '@angular/forms';
import { ForbiddenValidatorDirective } from 'src/helpers/forbiddenValue.directive';
import { ButtonModule } from 'primeng/button';
import { UserService } from 'src/services/user.service';
import { ApiInterceptor } from 'src/interceptor/api.interceptor';
import { MegaMenuModule } from 'primeng/megamenu';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ToastModule } from 'primeng/toast';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { CardModule } from 'primeng/card';
import { TermService } from 'src/services/term.service';
import { StatisticsService } from 'src/services/statistics.service';
import { SettingsService } from 'src/services/settings.service';
import { PermGuard, PermService } from 'src/services/perm.service';
import { PdfViewerModule } from '@syncfusion/ej2-angular-pdfviewer';
import { FormService } from 'src/services/form.service';
import { RoleService } from 'src/services/role.service';
import { EmployeeComponent } from './admin/employees/id/employee.component';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { SliderModule } from 'primeng/slider';
import { TabViewModule } from 'primeng/tabview';
import { CheckboxModule } from 'primeng/checkbox';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CalendarModule } from 'primeng/calendar';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { DividerModule } from 'primeng/divider';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { BankInputModule } from 'src/components/bank-input/bank-input.module';
import { EmployerDataModule } from 'src/components/employer-data/employer-data.module';
import { GeneralDataModule } from 'src/components/general-data/general-data.module';
import { PersonalInfoFormModule } from 'src/components/personal-info-form/personal-info-form.module';
import { PreviousInsuranceModule } from 'src/components/previous-insurance/previous-insurance.module';
import { TermModule } from 'src/components/terms/terms.module';
import { BlockUIModule } from 'primeng/blockui';
import { TransactionService } from 'src/services/transaction.service';
import { EmployeeApplicationsComponent } from './admin/employees/id/applications/applications.component';
import { Clipboard } from '@angular/cdk/clipboard';

@NgModule({
  declarations: [
    AppComponent, 
    EmployeeComponent,
    EmployeeApplicationsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ReactiveFormsModule,
    ButtonModule,
    MegaMenuModule,
    BreadcrumbModule,
    ToastModule,
    ConfirmPopupModule,
    PdfViewerModule,
    OrganizationChartModule,
    SliderModule,
    TabViewModule,
    CheckboxModule,
    CommonModule,
    FormsModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    DropdownModule,
    CalendarModule,
    TableModule,
    PanelModule,
    PersonalInfoFormModule,
    BankInputModule,
    EmployerDataModule,
    TermModule,
    GeneralDataModule,
    PreviousInsuranceModule,
    DataViewModule,
    TagModule,
    FileUploadModule,
    DividerModule,
    BlockUIModule
  ],
  providers: [
    PermGuard,
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService], multi: true
    },
    PermService,
    UserService,
    RoleService,
    FormService,
    PersonService,
    RefererService,
    StatisticsService,
    ApplicationService,
    EmployerService,
    EmploymentService,
    InsuranceService,
    SettingsService,
    TermService,
    AuthService,
    ToastService,
    MessageService,
    AddressService,
    ConfigService,
    ConfirmationService,
    Clipboard,
    TransactionService,
    {
      provide: JwtHelperService,
      useFactory: () => new JwtHelperService(),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    { provide: NG_VALIDATORS, useExisting: ForbiddenValidatorDirective, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export function initializeApp(appConfig: ConfigService) {
  return () => appConfig.load();
}