import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Email, IAuthResult, JWT_Payload } from 'froitzheim-shared';
import { shareReplay } from 'rxjs/operators';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastService } from './toast.service';
import { ConfigService } from './config.service';

@Injectable()
export class AuthService {
  constructor(
    private http: HttpClient,
    private jwt: JwtHelperService,
    private toast: ToastService,
  ) { }
  readonly path: string = '/auth';
  private url?: string;

  protected getUrl() {
    if (!this.url)
      this.url = ConfigService.settings.app.url + this.path;
    return <string>this.url;
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem('id_token') ?? undefined;
    const jwtToken = this.jwt.decodeToken(token) as JWT_Payload;
    return !this.jwt.isTokenExpired(token) && !!jwtToken.user.ID;
  }

  getToken(): JWT_Payload | undefined {
    const token = localStorage.getItem('id_token') ?? undefined;
    if(!token) return undefined;
    const jwtToken = this.jwt.decodeToken(token) as JWT_Payload;
    return jwtToken;
  }

  login(email: Email, password: string): Observable<IAuthResult> {
    const observable = this.http
      .post<IAuthResult>(`${this.getUrl()}/login`, { email, password })
      .pipe(shareReplay(1));
    observable.subscribe((auth) => this.setSession(auth));
    return observable;
  }

  private setSession(authResult: IAuthResult) {
    const expiresAt = moment().add(authResult.expiration, 'second');

    localStorage.setItem('id_token', authResult.access_token);
    localStorage.setItem(
      'expires_at',
      JSON.stringify(expiresAt.valueOf())
    );
  }

  logout() {
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    this.toast.warn('Logged out');
  }

  public isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiration =
      localStorage.getItem('expires_at') ??
      JSON.stringify(moment().add(60, 'second').valueOf());
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }
}
