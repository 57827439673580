import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IEmployer } from 'froitzheim-shared';
import { Service } from './base.service';

@Injectable()
export class EmployerService extends Service<IEmployer> {
    constructor(override http: HttpClient) {
        super();
    }
    override readonly path = '/employer';

    public getCreated(): Observable<IEmployer[]> {
        return this.http.get<IEmployer[]>(this.getUrl() + '/my');
    }
    public getAvailable(): Observable<IEmployer[]> {
        return this.http.get<IEmployer[]>(this.getUrl() + '/available');
    }
    public getPublic(): Observable<IEmployer[]> {
        return this.http.get<IEmployer[]>(this.getUrl() + '/public');
    }
}
