import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  FamilyStatus,
  Gender,
  ICustomer,
  IPerson,
} from 'froitzheim-shared';
import { EnumMap, EnumToArray } from 'src/helpers/enum.helpers';
import { debounce, Subject, timer } from 'rxjs';
import { AddressService } from 'src/services/address.service';
import moment from 'moment';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-personal-info-form',
  templateUrl: './personal-info-form.component.html',
  styleUrls: ['./personal-info-form.component.scss'],
})
export class PersonalInfoFormComponent implements OnInit {
  state: string[] = [
    'Baden-Württemberg',
    'Bayern',
    'Berlin',
    'Brandenburg',
    'Bremen',
    'Hamburg',
    'Hessen',
    'Mecklenburg-Vorpommern',
    'Niedersachsen',
    'Nordrhein-Westfalen',
    'Rheinland-Pfalz',
    'Saarland',
    'Sachsen',
    'Sachsen-Anhalt',
    'Schleswig-Holstein',
    'Thüringen',
  ];

  @Input() person!: ICustomer;
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();

  genders: EnumMap[] = EnumToArray(Gender);
  familyStates: EnumMap[] = EnumToArray(FamilyStatus);
  familyStatus: FamilyStatus = FamilyStatus.Unmarried;
  selectedState = '';
  activePersonIndex = 0;
  addPersonSelected = false;
  address?: Feature;
  results: Feature[] = [];
  minDate = moment('1900-01-01').toDate();
  maxDate = moment().add(-1, 'days').toDate();
  datePlaceholder = moment().add(-35, 'years').format('DD.MM.yy');

  searchAddress: Subject<string> = new Subject();

  form = new FormGroup({
    firstName: new FormControl(undefined, Validators.minLength(2)),
    name: new FormControl(undefined, Validators.minLength(2)),
    email: new FormControl(undefined),
    gender: new FormControl(Gender.Male),
    familyStatus: new FormControl(FamilyStatus.Unmarried),
    birthday: new FormControl(undefined), //, Validators.required),
    phone: new FormControl(undefined),
    city: new FormControl(undefined), //, Validators.minLength(2)),
    street: new FormControl(undefined), //, Validators.minLength(2)),
    streetNumber: new FormControl(undefined), //, Validators.minLength(1)),
    postcode:new FormControl(undefined), //, Validators.minLength(4))
    pension_insurance_number: new FormControl(undefined),
    birthCity: new FormControl(undefined),
    birthCountry: new FormControl(undefined),
    birthName: new FormControl(undefined),
    nationality: new FormControl(undefined),
  });
  constructor(private addressSearch: AddressService) { }

  ngOnInit(): void {
    this.searchAddress.pipe(debounce(() => timer(1000))).subscribe(async x => await this.addressSearch.fetchAddress(x));
    this.addressSearch.result.subscribe(x => this.results = x);
    this.form.patchValue(this.person);
    this.form.valueChanges.subscribe(x => {
      Object.assign(this.person, x);
    });
  }


  submitEnable(){
      let result = this.checkProperties(this.person, 'person', 'firstName', 'name', 'email', 'gender', 'familyStatus', 'birthday', 'city', 'street', 'postcode');
      if (result) result = this.checkProperties(this.person, 'person', 'pension_insurance_number') || this.checkProperties(this.person, 'person', 'birthCountry', 'birthCity')
      this.valid.emit(result);      
  }

  checkProperties<T extends Record<string, any>>(obj: T, prefix: string, ...properties: (keyof T)[]) {
    const missing: string[] = [];
    properties.forEach(prop => {
      if (!(obj)[prop]) {
        missing.push(prefix + '.' + <string>prop);
      }
    });
    return missing.length === 0;
  }

  // onSelect(_event: any): void {
  //   this.activePersonIndex = _event.index;
  //   this.addNewPerson();
  //   this.addPersonSelected = true;
  // }
  // addNewPerson(): void {
  //   this.persons.push(new Person());
  //   this.persons.push(this.person); 
  // }
  search(query: string) {
    this.searchAddress.next(query);
  }

  fillForm(event: Feature) {
    if (event == undefined)
      return;
    this.person.street = event.properties.street;
    this.person.streetNumber = event.properties.housenumber;
    this.person.city = event.properties.city;
    this.person.postcode = event.properties.postcode;
    this.selectedState = event.properties.state;
    this.address = undefined;
  }


}

type FeatureCollection = {
  features: Feature[]
  type: string
}

type Feature = {
  geometry: Geometry,
  properties: Properties
  type: string
}

type Geometry = {
  coordinates: number[]
  type: string
}

type Properties = {
  country: string,
  city: string,
  housenumber: string,
  postcode: string,
  state: string,
  street: string,
  type: string,
}
