import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUser } from 'froitzheim-shared';
import { Service } from './base.service';
import { ConfigService } from './config.service';

@Injectable()
export class UserService extends Service<IUser> {
  
  constructor(override http: HttpClient) {
    super();
  }

  override readonly path = '/user';

  public count() {
    return this.http.get<number>(`${this.getUrl()}/count`, this.getHeaders());
  }

  public setPassword(userId: number, password: string) {
    return this.http.post<IUser>(`${ConfigService.settings.app.url}/auth/reset`, { userId, password }, this.getHeaders());
  }

  public getByReferal(referal: string): Observable<IUser> {
    return this.http.get<IUser>(this.getUrl() + '/referal/' + referal, this.getHeaders());
  }

  public addApplication(id: number, applicationId: number): Observable<IUser> {
    return this.http.post<IUser>(`${this.getUrl()}/${id}/application/${applicationId}`, {});
  }

  public deleteApplication(id: number, applicationId: number): Observable<IUser> {
    return this.http.delete<IUser>(`${this.getUrl()}/${id}/application/${applicationId}`);
  }
  
  public getWithDescendants(id: number): Observable<IUser> {
    return this.http.get<IUser>(`${this.getUrl()}/${id}/children`, this.getHeaders());
  }

  public getAllWithDescendants(): Observable<IUser[]> {
    return this.http.get<IUser[]>(`${this.getUrl()}/children`, this.getHeaders());
  }

  public getMyChildren(): Observable<IUser[]> {
    return this.http.get<IUser[]>(`${this.getUrl()}/me/children`, this.getHeaders());
  }

  public getMyChildrenFlat(): Observable<IUser[]> {
    return this.http.get<IUser[]>(`${this.getUrl()}/me/children/flat`, this.getHeaders());
  }
  public getInsurances(id: number): Observable<number[]> {
    return this.http.get<number[]>(`${this.getUrl()}/${id}/insurances`, this.getHeaders());
  }
  public setInsurances(id: number, insurances: number[]): Observable<number[]> {
    return this.http.put<number[]>(`${this.getUrl()}/${id}/insurances`, insurances, this.getHeaders());
  }

  public getTotalProvision(id: number): Observable<number> {
    return this.http.get<number>(`${this.getUrl()}/${id}/total_provision`, this.getHeaders());
  }

  public getProvision(id: number): Observable<number> {
    return this.http.get<number>(`${this.getUrl()}/${id}/provision`, this.getHeaders());
  }

  public OTLEdit(id: number): Observable<{otl: string}> {
    return this.http.get<{otl: string}>(`${this.getUrl()}/otl/${id}`, this.getHeaders());
  }

  public putOTLEditMail(id: number): Observable<string> {
    return this.http.put<string>(`${this.getUrl()}/otl/${id}/mail`, {}, this.getHeaders());
  }

  getOTLUser(otl: string): Observable<IUser>{
    return this.http.get<IUser>(`${this.getUrl()}/registration/${otl}`, this.getHeaders());
  }
}
