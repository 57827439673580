import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEmployment } from 'froitzheim-shared';
import { Service } from './base.service';

@Injectable()
export class EmploymentService extends Service<IEmployment> {
    constructor(override http: HttpClient) {
        super();
    }

    override readonly path = '/employment';
}
