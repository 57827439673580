import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPerson } from 'froitzheim-shared';
import { Service } from './base.service';

@Injectable()
export class PersonService extends Service<IPerson> {

  constructor(override http: HttpClient) {
    super();
  }

  override readonly path = '/person';

  public count() {
    return this.http.get<number>(`${this.getUrl()}/count`, this.getHeaders());
  }
  public latest(amount = 10) {
    return this.http.get<IPerson[]>(`${this.getUrl()}/latest/${amount}`, this.getHeaders());
  }
}
