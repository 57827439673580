import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ConfigService {
    static settings: {app: {url?: string}};

    constructor(private http: HttpClient) { }
    load() {
        let API_URL = (window['envConfig' as unknown as number] as any)?.api as string | undefined;
        if (API_URL?.startsWith("{{")) API_URL = undefined;
        ConfigService.settings = {
            app: {
                url: [undefined, null, ''].includes(API_URL?.trim()) ? 'http://localhost:3001' : API_URL,
            }
        };
        // const jsonFile = 'config.json';
        // return new Promise<void>((resolve, reject) => {
        //     lastValueFrom(this.http.get(jsonFile)).then((response: any) => {
        //         ConfigService.settings = <any>response;
        //         resolve();
        //     }).catch((response: any) => {
        //         reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
        //     });
        // });
    }
}