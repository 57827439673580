import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { FileType, IApplication, IFile, InsuranceData, ITerm } from 'froitzheim-shared';
import { Service } from './base.service';
import { SubmitError } from 'src/helpers/enum.helpers';

@Injectable()
export class ApplicationService extends Service<IApplication> {
  constructor(override http: HttpClient) {
    super();
  }

  override readonly path = '/application';

  public async getByIdAsync(id: number): Promise<{ application: IApplication, insuranceData?: InsuranceData }> {
    const result = (await lastValueFrom(this.http.get<IApplication>(this.getUrl() + '/' + id, this.getHeaders())));
    return {
      application: result,
      insuranceData: result.previousInsurance?.data
    };
  }

  public addReferer(refererId: number, applicationId: number): Observable<IApplication> {
    return this.http.post<IApplication>(`${this.getUrl()}/${applicationId}/referer/${refererId}`, {});
  }
  public webdav(id: number): Observable<void> {
    return this.http.put<void>(`${this.getUrl()}/webdav/${id}`, {});
  }
  public removeReferer(refererId: number, applicationId: number): Observable<IApplication> {
    return this.http.delete<IApplication>(`${this.getUrl()}/${applicationId}/referer/${refererId}`);
  }

  public allMe() {
    return this.http.get<IApplication[]>(`${this.getUrl()}/me`);
  }
  public allUser(userID: string) {
    return this.http.get<IApplication[]>(`${this.getUrl()}/user/${userID}`);
  }
  public countAll() {
    return this.http.get<number>(`${this.getUrl()}/count`);
  }
  public countMine() {
    return this.http.get<number>(`${this.getUrl()}/count/me`);
  }

  public async submit(application: IApplication, terms: { agreed: boolean, term: ITerm}[]) {
    return await lastValueFrom(this.http.post<IApplication | SubmitError>(`${this.getUrl()}/submit`, {application, terms}, this.getHeaders()));
  }

  public exportSelected(ids: number[], emailNotification: boolean, toNovitas = false, onEnd?: () => Promise<any> | any ) {
    return this.http.post(`${this.getUrl()}/export/selected`, ids, { params:  { export: toNovitas ? 'novitas' : 'default', notify: emailNotification }, ...this.getAuthHeaders(), responseType: 'blob', observe: 'response' }).subscribe(x => {
      if (!x.body || !x.body.size)
        return;
      const _url = window.URL.createObjectURL(x.body);
      const a = document.createElement('a');
      a.href = _url;
      a.download = 'export.xlsx';
      a.target = '_blank';
      a.click();
      if(onEnd) Promise.resolve(onEnd());
    });
  }
  getFiles(id: number) {
    return this.http.get<IFile[]>(`${this.getUrl()}/${id}/download`, this.getHeaders());
  }

  uploadFile(id: number, file: File, type: FileType = FileType.Undefined) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<IFile>(`${this.getUrl()}/${id}/upload/${type}`, formData, this.getAuthHeaders());
  }
  downloadFile(id: number, file: IFile) {
    this.http.get(`${this.getUrl()}/${id}/download/${file.fileName}`, { ...this.getAuthHeaders(), responseType: 'blob', observe: 'response' }).subscribe(x => {
      if (!x.body)
        return;
      const _url = window.URL.createObjectURL(x.body);
      const a = document.createElement('a');
      a.href = _url;
      a.download = file.originalFileName;
      a.target = '_blank';
      a.click();
    });
  }

  deleteFile(id: number, file: IFile) {
    return this.http.delete(`${this.getUrl()}/${id}/download/${file.fileName}`, this.getAuthHeaders());
  }
}
