import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPayment, ITransaction } from 'froitzheim-shared';
import { DateTime } from 'luxon';
import { Service } from './base.service';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class TransactionService extends Service<IPayment> {
  
  constructor(override http: HttpClient) {
    super();
  }

  override readonly path = '/payment';
  
  public summarizeTransactions() {
    const url = this.getUrl() + '/summary';
    return this.http.get<Record<string, ITransaction[]>>(url, this.getHeaders());
  }
  public summarizeTransactionsRange(from: DateTime, to: DateTime) {
    const url = this.getUrl() + `/summary/${from.toISODate()}/${to.toISODate()}`;
    return this.http.get<Record<string, ITransaction[]>>(url, this.getHeaders());
  }
  public summarizeMyTransactions() {
    const url = this.getUrl() + '/me/summary';
    return this.http.get<ITransaction[]>(url, this.getHeaders());
  }
  public async summarizeUserTransactionsRange(userID: number, from: DateTime, to: DateTime) {
    const url = this.getUrl() + `/${userID}/summary/${from.toISODate()}/${to.toISODate()}`;
    return await lastValueFrom(this.http.get<ITransaction[]>(url, this.getHeaders()));
  }

  public payout() {
    this.http.get(`${this.getUrl()}/payout`, {...this.getAuthHeaders(), responseType: 'blob', observe: 'response' }).subscribe(x => {
      if (!x.body || !x.body.size)
        return;
      const _url = window.URL.createObjectURL(x.body);
      const a = document.createElement('a');
      a.href = _url;
      a.download = `auszahlungen-${DateTime.now().toISODate()}.xlsx`;
      a.target = '_blank';
      a.click();
    });
  }

  public export(from: DateTime, to: DateTime) {
    this.http.get(`${this.getUrl()}/summary/${from.toISODate()}/${to.toISODate()}/export`, {...this.getAuthHeaders(), responseType: 'blob', observe: 'response' }).subscribe(x => {
      if (!x.body || !x.body.size)
        return;
      const _url = window.URL.createObjectURL(x.body);
      const a = document.createElement('a');
      a.href = _url;
      a.download = `auszahlungen-${DateTime.now().toISODate()}.xlsx`;
      a.target = '_blank';
      a.click();
    });
  }
}
